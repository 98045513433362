import React from 'react';
import Header from './components/Header';
import MainBanner from './HOC/MainBanner';
import AboutCompany from './HOC/AboutCompany';
import Services from './HOC/Services';
import Ordering from './HOC/Ordering';
import Faq from './HOC/Faq';
import Contacts from './HOC/Contacts';
import Footer from './HOC/Footer';
import AnimatedWrapper from './components/AnimatedWrapper';

function App() {
  return (
    <div className="page_wrapper">
      <Header />
      <div className="page">
        {/* <AnimatedWrapper>
          <MainBanner />
        </AnimatedWrapper> */}
        <AnimatedWrapper>
          <Services />
        </AnimatedWrapper>
        <AnimatedWrapper
          style={{ position: 'relative', zIndex: '1', top: '0' }}
        >
          <Ordering />
        </AnimatedWrapper>
        <AnimatedWrapper>
          <AboutCompany />
        </AnimatedWrapper>
        <AnimatedWrapper>
          <Faq />
        </AnimatedWrapper>
        <Contacts />
        <Footer />
        {/* <Promo /> */}
      </div>
    </div>
  );
}

export default App;
