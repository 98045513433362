import React, { useState } from 'react';
import { css, cx } from 'linaria';
import { Link, useHistory } from 'react-router-dom';
import SelectCity from './SelectCity';
import { scrollToSection, phonePipe } from '../utils';
import { useSelector } from 'react-redux';
import { COLORS } from '../assets/styles';
import AnimatedLogo from './AnimatedLogo';

const menus = [
  {
    title: 'Услуги',
    url: 'services',
  },
  {
    title: 'О компании',
    url: 'about',
  },
  {
    title: 'Знаете ли вы',
    url: 'faq',
  },
  {
    title: 'Контакты',
    url: 'contacts',
  },
];

const MobileHeader = ({ className }) => {
  const [isOpen, _setIsOpen] = useState(false);
  let setIsOpen = (state) => {
    document.body.style.overflow = state ? 'hidden' : 'auto';
    _setIsOpen(state);
  };
  const history = useHistory();
  const phone = useSelector((state) => state.settings.phone_number);
  return (
    <>
      <div className={className}>
        <div className={cx('container', classes.container)}>
          <div className={classes.container_left}>
            <Burger onClick={() => setIsOpen(!isOpen)} state={isOpen} />
            <div className={classes.select_city}>
              <SelectCity />
            </div>
          </div>
          {/* (づ￣ ³￣)づ */}
          <div className={classes.phone_number_wrapper}>
            <PhoneView
              phone={phone}
              inHeader
              goOrder={() => {
                history.push('/');
                setIsOpen(false);
                setTimeout(() => {
                  scrollToSection('ordering');
                });
              }}
            />
          </div>
          <Link to="/" className={classes.logo}>
            <AnimatedLogo isMobile />
          </Link>
        </div>
      </div>
      <div
        className={cx(
          classes.header_content,
          isOpen && classes.header_content_active
        )}
      >
        <Menu onChange={() => setIsOpen(false)} />
      </div>
    </>
  );
};

export default MobileHeader;

const Menu = ({ onChange }) => {
  const history = useHistory();
  const changeMenu = (menuId) => {
    history.push('/');
    setTimeout(() => {
      scrollToSection(menuId);
    });
    onChange && onChange();
    return (e) => e.preventDefault();
  };
  return (
    <nav className={classes.menu}>
      <div className={(classes.select_city, classes.select_city_in_menu)}>
        <SelectCity />
      </div>
      <ul>
        {menus.map((l, i) => (
          <li key={i}>
            <a
              className={cx(classes.menu_item)}
              onClick={(e) => changeMenu(l.url)(e)}
              href={`#${l.url}`}
            >
              {l.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const Burger = ({ state, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cx(classes.burger, state && classes.burger_open)}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

const PhoneView = ({ phone, goOrder, inHeader }) => {
  return (
    <div
      className={cx(
        classes.ordering_wrapper,
        inHeader && classes.ordering_wrapper_header
      )}
    >
      <a
        href="#ordering"
        className={classes.ordering_link}
        onClick={(e) => {
          e.preventDefault();
          goOrder();
        }}
      >
        Оставить заявку
      </a>
      <a className={classes.ordering_phone} href={'tel:' + phone}>
        {phonePipe(phone)}
      </a>
    </div>
  );
};

const classes = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  container_left: css`
    display: flex;
    align-items: center;
    flex-grow: 1;
    @media screen and (max-width: 531px) {
      flex-grow: 0;
      padding-right: 12px;
    }
  `,
  select_city: css`
    padding-left: 12px;
    flex-grow: 1;
    margin-bottom: -6px;
    & .items {
      position: fixed;
      left: 0;
      right: 0;
      top: 100px !important;
      & .items {
        width: auto;
        position: absolute;
        top: 10px !important;
        left: 10px;
        right: 10px;
        margin-top: 68px;
        box-shadow: 0px 4px 10px rgba(70, 16, 16, 0.2),
          0px -4px 10px rgba(70, 16, 16, 0.2);
      }
    }
    @media screen and (max-width: 530px) {
      display: none;
    }
  `,

  header_content: css`
    overflow: auto;
    background-color: #fff;
    position: fixed;
    top: -1000px;
    bottom: 100%;
    transition: 0.3s;
    left: 0;
    width: 100%;
    padding: 112px 24px 48px;
    opacity: 0;
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  header_content_active: css`
    top: 0;
    bottom: 0;
    opacity: 1;
  `,
  logo: css`
    height: 43px;
    & img {
      height: 100%;
    }
  `,
  ordering_wrapper: css`
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    margin-right: 24px;
  `,
  ordering_wrapper_header: css`
    @media screen and (min-width: 500px) {
      display: flex;
    }
  `,
  ordering_link: css`
    color: ${COLORS.primary};
    font-size: 14px;
  `,
  ordering_phone: css`
    font-size: 20px;
    white-space: nowrap;
    color: #000;
    font-weight: bold;
    text-decoration: none;
    margin-top: 8px;
    @media screen and (max-width: 500px) {
      font-size: 22px;
    }
    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
  `,
  burger: css`
    padding: 7px 4px;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    & span {
      display: block;
      width: 24px;
      height: 2px;
      background-color: #000;
      transition: 0.3s;
    }
  `,
  burger_open: css`
    & span {
      &:nth-child(1) {
        transform: rotate(-45deg) translate(-7px, 6px);
      }
      &:nth-child(3) {
        transform: rotate(45deg) translate(-6px, -5px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
    }
  `,
  menu: css`
    margin-top: 48px;
    flex-grow: 1;
    & ul {
      padding: 0;
      margin: 0;
      & li {
        list-style: none;
        display: block;
      }
    }
  `,
  menu_item: css`
    color: #000;
    display: block;
    text-decoration: none;
    padding: 8px 0;
    font-size: 18px;
  `,
  select_city_in_menu: css`
    margin-left: auto;
    position: relative;
    z-index: 100;
    & .items {
      width: calc(100% + 48px);
      margin: 0 -24px;
      /* border-radius: 0; */
      & .items {
        width: calc(100% - 20px);
        margin: 78px 10px 0;
        box-shadow: 0px 4px 10px rgba(70, 16, 16, 0.2),
          0px -4px 10px rgba(70, 16, 16, 0.2);
      }
    }
    @media screen and (min-width: 531px) {
      display: none;
    }
  `,
  phone_number_wrapper: css`
    @media screen and (max-width: 531px) {
      margin-right: auto;
      flex-grow: 1;
    }
  `,
};
