import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import closeIcon from '../assets/images/close.svg';

const PhotoViewer = ({ src, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => (document.body.style.overflow = null);
  }, []);

  return (
    <Wrapper onClick={onClose}>
      <CloseButton onClick={onClose}>
        <img src={closeIcon} alt="" />
      </CloseButton>
      <Image
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        src={src}
      ></Image>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.36);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 36px;
  width: 36px;
  border-radius: 32px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.8);
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 800px) {
    top: 24px;
    right: 24px;
    height: 42px;
    width: 42px;
  }
  & img {
    width: 22px;
    height: 22px;
  }
`;

export default PhotoViewer;
