/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { css } from 'linaria';
import ServiceSectionDetails from '../components/ServiceSectionDetails';
import { useSelector, useDispatch } from 'react-redux';
import { selectService } from '../store/slices/ordering';
import { scrollToSection } from '../utils';
import { SIZES } from '../assets/styles';
import {
  getServiceDateAction,
  getServicePricesAction,
} from '../store/slices/settings';

const WATER_SERVICE_ID = '1';

function Services() {
  const dispatch = useDispatch();

  const services = useSelector((state) => state.settings.services) || [];
  const currentCity = useSelector((state) => state.cities.currentCity) || '';
  const cities = useSelector((state) => state.cities.cities) || [];

  const waterService =
    (services || []).find(
      (service) => service.service_id === WATER_SERVICE_ID
    ) || {};

  useEffect(() => {
    if (waterService && currentCity && cities && cities.length) {
      const findedCity = cities.find(
        (c) => c.city.trim().toLowerCase() === currentCity.trim().toLowerCase()
      );
      getServiceDateAction(dispatch)([waterService.service_id], findedCity.id);
      getServicePricesAction(dispatch)(findedCity.id);
      dispatch(selectService(waterService));
    }
  }, [waterService.service_id, currentCity, cities.length]);
  const servicesDates = waterService.dates || [];

  useEffect(() => {
    dispatch(selectService(waterService));
  }, [servicesDates.length]);

  const setService = () => {
    scrollToSection('ordering');
  };

  return (
    <section scroll-data="services" className={classes.section}>
      <div className="container">
        <ServiceSectionDetails
          title={waterService.name}
          photo={waterService.photo?.file?.url}
          price={waterService.price}
          date={
            (waterService.dates || [])
              .map((e) => new Date(e.date))
              .filter(
                (e) =>
                  new Date(e.setHours(0, 0, 0, 0)).getTime() >=
                  new Date(new Date().setHours(0, 0, 0, 0)).getTime()
              )
              .sort((a, b) => a.getTime() - b.getTime())[0]
          }
          description={waterService.description}
          onEnroll={() => setService()}
        />
      </div>
    </section>
  );
}

export default Services;

const classes = {
  active_item_header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    border-right: none;
    @media screen and (max-width: ${SIZES.md}px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  `,
  container: css`
    border-right: none;
  `,
  title: css`
    @media screen and (max-width: ${SIZES.md}px) {
      margin-bottom: 24px;
    }
  `,
  p: css`
    font-size: 20px;
  `,
  header: css`
    width: 50%;
  `,
  slider_detail: css`
    padding: 10px;
  `,
  section: css`
    padding: 48px 0 64px;
    @media screen and (max-width: ${SIZES.md}px) {
      padding: 32px 0 64px;
    }
    overflow: hidden;
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      width: 160px;
      position: absolute;
    }
  `,
  items_wrapper_right: css`
    justify-content: flex-start;
  `,
  items_wrapper_left: css`
    justify-content: flex-end;
  `,
  items_wrapper: css`
    width: 0;
    display: flex;
    padding-top: 81px;
    @media screen and (max-width: ${SIZES.md}px) {
      padding-top: 111px;
    }
  `,
  services: css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-top: 2px;
  `,
  service_item: css`
    min-width: 300px;
    width: 300px;
    padding: 0 15px;
  `,
  service_item_active: css`
    max-width: 655px;
    width: 100%;
    padding: 0 15px;
    z-index: 10;
  `,
};
