import { styled } from 'linaria/react';
import React, { useEffect, useRef, useCallback, useState } from 'react';

const AnimatedWrapper = ({ children, ...props }) => {
  const containerRef = useRef();
  const [active, setActive] = useState(false);

  const onScroll = useCallback(() => {
    const zoom = window.innerWidth <= 800 ? 1 : 0.69;
    if (
      containerRef.current.getBoundingClientRect().top -
        (window.innerHeight + 150) * (1 / zoom) <
      0
    ) {
      setActive(true);
    }
  }, []);

  useEffect(() => {
    if (active) {
      document.removeEventListener('scroll', onScroll);
    }
  }, [active]);

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <ContentAnimated
      className={active ? 'active' : ''}
      ref={containerRef}
      {...props}
    >
      {children}
    </ContentAnimated>
  );
};

export default AnimatedWrapper;

const ContentAnimated = styled.div`
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  top: 100px;
  transition: 1s;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
`;
