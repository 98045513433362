export const COLORS = {
  primary: '#E32B04',
  purple: '#F9D5D5',
  yellow: '#FACC33',
};

export const SIZES = {
  md: 1000,
  sm: 600,
};
