import React from 'react';
import { styled } from 'linaria/react';

export default () => {
  return (
    <Wrapper>
      <iframe src="https://yandex.ru/maps-reviews-widget/128212829656?comments"></iframe>
      <a
        href="https://yandex.ru/maps/org/moy_schetchik/128212829656/"
        target="_blank"
      >
        Мой счетчик на карте Владимира — Яндекс Карты
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;

  & iframe {
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-sizing: border-box;
    min-height: 500px;
  }

  & a {
    box-sizing: border-box;
    text-decoration: none;
    color: #b3b3b3;
    font-size: 10px;
    font-family: YS Text, sans-serif;
    padding: 0 20px;
    position: relative;
    top: -24px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;
